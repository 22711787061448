.features_block {
    margin: 0;
    margin-top: 0;
    width: 100%;
    min-height: 450px;
    background-color: var(--bg-light-gray-1);
}

.features_block .features_card {
    height:410px;
    background-color: var(--bg-light-gray-2);
}

.features_block .features_card h5{
    margin-top:110px;
    margin-bottom:0;
}

.features_block .features_card p{
    opacity: 1;
    font-size:15px;
}

.features_block .features_card a{
    opacity: 1;
    font-weight: 600;
    text-decoration: underline;
}

.features_card .feature-icon {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 32px;
    color: #154a7b;
    opacity: .86;
}


@media only screen and (max-width: 440px) {
    
    .features_block .features_card p{
        font-size:14px;
    }

    .features_card .feature-icon {
        top: 26px;
    }

}

@media only screen and (max-width: 1023px) and (min-width:901px) {
    
    .features_block .features_card {
        height:366px;
    }

}

@media only screen and (max-width: 900px) and (min-width:761px) {
    
    .features_block .features_card {
        height:412px;
    }

}

@media only screen and (max-width: 760px) and (min-width:768px) {
    
    .features_block .features_card {
        height:340px;
    }

}

@media only screen and (max-width: 767px) and (min-width:701px) {
    
    .features_block .features_card {
        height:340px;
    }

}

@media only screen and (max-width: 700px) and (min-width:601px) {
    
    .features_block .features_card {
        height:360px;
    }

}

@media only screen and (max-width: 600px) and (min-width:463px) {
    
    .features_block .features_card {
        height:410px;
    }

}

@media only screen and (max-width: 462px) and (min-width:401px) {
    
    .features_block .features_card {
        height:450px;
    }

}

@media only screen and (max-width: 400px) and (min-width:361px) {
    
    .features_block .features_card {
        height:470px;
    }

}

@media only screen and (max-width: 360px) and (min-width:331px) {
    
    .features_block .features_card {
        height:540px;
    }

}

@media only screen and (max-width: 330px) and (min-width:301px) {
    
    .features_block .features_card {
        height:580px;
    }

}

@media only screen and (max-width: 300px) {
    
    .features_block .features_card {
        height:580px;
    }

}