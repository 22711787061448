.card .i-card-img {
    background-size: cover !important;
    border-radius: 4px;
}

.bg_img_1 {
    background: url(../../../../img/stock/c-4.jpg) no-repeat center;
}

.bg_img_2 {
    background: url(../../../../img/stock/c-1.jpg) no-repeat center;
}

.bg_img_3 {
    background: url(../../../../img/stock/p-3.jpg) no-repeat center;
}

.bg_img_4 {
    background: url(../../../../img/stock/p-7.jpg) no-repeat center;
}

.project-card .row-card {
    display: flex;
}
.project-card .col-card {
    flex: 1;
    padding: 1em;
}
.project-card .project-card-bg {
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--f7-theme-color);
    width: 100%;
}