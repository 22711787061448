.gsp-card-1 {
  background-image: url('../../../img/get-started-guide/get-started-1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 276px;
}

.gsp-card-2 {
    background-image: url('../../../img/get-started-guide/get-started-2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 276px;
  }

  .gsp-card-3 {
    background-image: url('../../../img/get-started-guide/get-started-3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 276px;
  }

  .gsp-card-4 {
    background-image: url('../../../img/get-started-guide/get-started-4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 276px;
  }

  .gsp-card-5 {
    background-image: url('../../../img/get-started-guide/get-started-5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 276px;
  }