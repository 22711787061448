.stripe-payment-demo {
    background: url(../../../img/stripe/payment_ticket.png) no-repeat;
    background-size: 100% auto;
    background-position:center top;
    width:290px;
    margin-left:auto;
    margin-right:auto;
    height: 100%;
    margin-top: 20px;
}
.stripe-payment-demo-col {
    height: 400px;
}