.video-card-header-pic .card-header {
    height: 220px;
    background-size: cover;
    background-position: center;
    color: #fff;
}

.video-card-header-pic .card-header .icon {
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0px 0px 5px rgba(0,0,0,.5);
}

.video-card-header-pic .title {
    font-size: 21px;
    margin-bottom: 0;
    font-weight: 500;
}

.video-card-header-pic:hover {
    background-color: #ecf0f1;
}

.video-card-header-pic {
    transition-duration: .2s;
    cursor:pointer;
}


.video-card-header-pic .icon {
    transition-duration: .2s;
}

.video-card-header-pic:hover .icon {
    color:#ecf0f1;
}

@media only screen and (max-width: 767px) {
    
    .video-card-header-pic {
        max-width: 420px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
}

@media only screen and (max-width: 450px) {
    
    .video-card-header-pic {
        max-width: calc(100% - 30px);
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
}

@media only screen and (min-width: 820px) {
    .videoplayer-popup {
        --f7-popup-tablet-width: 800px;
        --f7-popup-tablet-height: 515px;
    }
}