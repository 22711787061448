:root {
    --f7-theme-color: #13395B;
    --f7-theme-color-rgb: 19, 57, 91;
    --f7-theme-color-shade: #0e2b43;
    --f7-theme-color-tint: #184f7f;
    --f7-bars-bg-color:  #13395B;
    --f7-bars-link-color: #ffffff;
    --f7-bars-text-color: #ffffff;
    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
    --f7-block-title-white-space: normal;
    --f7-page-bg-color:#ffffff;
    /*--f7-page-bg-color:#f5f6fa;*/
    --f7-list-item-text-max-lines: 12;
    --f7-block-font-size: 15px;
    --f7-card-font-size: 15px;

    --bg-light-gray-1: #f5f6fa;
    --bg-light-gray-2: #f0f1f5;
  }

  @media only screen and (min-width: 768px) {
    .hideFullScreen{
      display: none !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .hideMobileScreen {
      display: none !important;
    }
  }

  .logo-iwd {
    background: url(../img/logo/p-logo.svg) no-repeat center;
    background-size: contain;
    width: 30px;
    height: 60px;
    margin-left: 18px;
  }

  .c-text-link {
    text-decoration: underline;
  }

  h2 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;
    margin-top: var(--f7-block-margin-vertical);
    margin-bottom: var(--f7-block-title-margin-bottom);
    margin-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    margin-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
  }
  h5 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 640px) and (min-width: 461px) {
  h2 {
    font-size: 30px;
    line-height: 34px;
  }
  h5 {
  font-size: 22px;
  line-height: 26px;
  }

}

@media only screen and (max-width: 460px) {
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  h5 {
  font-size: 20px;
  line-height: 24px;
  }

}

.logo-stripe {
  background: url(../img/logo/stripe_logo.svg) no-repeat center;
  background-size: 100% auto;
}