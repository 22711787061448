.greeting_header {
    font-size: 46px;
    max-width: 600px;
    color: var(--f7-bars-text-color);
    line-height: 56px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 0px 0px 10px var(--f7-bars-bg-color);
}

.greeting_body {
    font-size: 22px;
    color: var(--f7-bars-text-color);
    line-height: 32px;
    max-width: 600px;
    margin-left: 0;
    margin-top: 20px;
    text-shadow: 0 0 10px var(--f7-bars-bg-color);
}

.greeting_block {
    top: 0;
    margin: 0;
    width: 100%;
    min-height: 608px;
    background-color: var(--f7-bars-bg-color);
}

.greeting_block .border-divider {
    width: 100%;
    height: 2px;
    background-color: #121113;
    position: absolute;
    top: 708px

}

.greeting_block .greeting-logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 330px;
    height: 60px;
    background: url(../../../../img/logo/p-f-logo.svg) no-repeat center;
    background-size: cover;
}

.greeting_block .left-menu {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 60px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.greeting_block .left-menu .c-link {
    padding: 0 15px;
    font-size: 18px;
    color: var(--f7-bars-text-color);
}

.greeting_block .left-menu .icon-only {
    margin: 0;
}

.greeting_block .left-menu .button {
    margin-left: 20px;
}

.greeting_block .greeting_text_block {
    width: 100%;
    padding-top: 160px;
    z-index: 1;
    margin-bottom: -140px;
}

.greeting_block .greeting_text_block .greeting_text_inner {
    width: 80%;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
}


.greeting_block .greeting_btn{
    margin-top: 30px;
    background-color:#9b59b6;
    text-transform: none;
    width: 220px;
    font-size: 18px;
    box-shadow: 0 0 20px var(--f7-bars-bg-color);
}

.greeting-particles {
    height: 246px;
}


@media only screen and (max-width: 1120px) {

    .greeting_block .greeting-logo {
        position: absolute;
        top: 20px;
        left: 40px;
        width: 37px;
        height: 53px;
        background: url(../../../../img/logo/p-logo.svg) no-repeat center;
        background-size: cover;
    
    }

}

@media only screen and (max-width: 1200px) and (min-width: 980px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .8) + 266px) 100%;
    }
}

@media only screen and (max-width: 979px) and (min-width: 768px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .4) + 336px) 100%;
    }
}

@media only screen and (max-width: 914px) and (min-width: 768px){

    .greeting_block .left-menu .c-link {
        padding: 0 8px;
        font-size: 17.5px;
    }

    .greeting_block .greeting-logo {
        left: 30px;
    }

    .greeting_block .left-menu {
        right: 30px;
    }
    

}

@media only screen and (max-width: 767px) {
    
    
    .greeting_block {
        min-height: 498px;
    }

    .greeting-particles {
        height: 154px;
    }
    
    .greeting_block .border-divider {
        width: 100%;
        height: 2px;
        background-color: #1F1B62;
        position: absolute;
        top: 498px
    
    }

    .greeting_block .greeting-logo {
        left: 20px;
    }

    .greeting_block .left-menu {
        right: 0;
    }

    .greeting_header {
        font-size: 28px;
        line-height: 46px;
    }
    
    .greeting_body {
        font-size: 18px;
        line-height: 24px;
    }

    .greeting_block .greeting_text_block {
        width: 100%;
        padding-top: 130px;
        margin-bottom: -72px;
    }
    
    .greeting_block .greeting_text_block .greeting_text_inner {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    

  }

  @media only screen and (max-width: 340px){

    .greeting_block {
        min-height: 440px;
        padding-bottom: 20px;
    }

    .greeting_header {
        font-size: 30px;
    }

}