.NavbarIconView {
    width: 48px;
}

.navbar .title {
    text-shadow: 1px 1px 0 rgba(0,0,0,.23);
}

.navbar .link {
    text-shadow: 1px 1px 0 rgba(0,0,0,.23);
}

.navbar .user_profile_btn {
    width: 36px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: contain;
}

.navbar .right .button {
    margin-right:18px;
    margin-left: 18px;
}


@media only screen and (max-width: 767px) {
    
    .navbar .right .button {
        margin-right:0;
        margin-left: 18px;
    }

}

.navbar .navbar-inner .c-link {
    padding: 0 13px;
    font-size: 18px;
    color: #ffffff;
}

@media only screen and (max-width: 914px) and (min-width: 768px){

    .navbar .navbar-inner .c-link {
        padding: 0 10px;
    }

}