.react-pdf__Page__canvas {
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 2px 5px rgba(0,0,0,.4);
}

.react-pdf__Document {
    margin-top: -16px;
}

.react-pdf__Page {
    margin-top: 16px;
    margin-bottom: 16px;
    
}