.get_started_block {
    margin: 0;
    margin-top: 0;
    width: 100%;
    background-color: var(--f7-theme-color-tint);
}

.get_started_block .get_started_card {
    height:180px;
    background-color:var(--f7-theme-color);
}

@media only screen and (max-width: 900px) and (min-width:761px) {
    
    .get_started_block .get_started_card {
        height:212px;
    }

}

.get_started_block .get_started_card h5{
    margin-top:0;
    margin-bottom:20px;
}

.get_started_block .get_started_card p{
    opacity: 0.8;
    color:#ffffff;
    font-size:15px;
}


.get_started_card .step_nr {
    position: absolute;
    right: 12px;
    top: 12px;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 22px;
    color: #ffffff;
}